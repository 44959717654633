import React from "react";
import { FaRegCalendarAlt, FaBirthdayCake } from "react-icons/fa";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoSchoolOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { IoIosInformationCircleOutline, IoIosArrowDown } from "react-icons/io";

const About = () => {
  return (
    <div className="home-container p-3 overflow-x-hidden">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
        className="border-bottom d-flex flex-wrap flex-row-reverse justify-content-around gap-10"
      >
        <img
          src="/cover.jpg"
          alt="cover"
          className="img-fluid mb-2"
          style={{
            width: window.innerWidth >= 768 ? "50%" : "100%",
          }}
        />
        <div className="d-flex flex-column flex-grow-1 justify-content-center">
          <h1 className="text-info">Pham Van Duong</h1>
          <a className="text-warning mb-3" href="https://github.com/frey-eth">
            Software developer
          </a>
          <p className="d-flex align-items-center gap-2 text-white">
            <FaBirthdayCake />
            Date of Birth: 28/11/2002
          </p>
          <p className="d-flex align-items-center gap-2 text-white">
            <FaLocationDot />
            Location: Ho Chi Minh City
          </p>
          <a
            className="text-white mb-3 text-decoration-none d-flex align-items-center gap-2"
            href="mailto:phamvanduong.work@gmail.com"
          >
            <MdEmail />
            Email: phamvanduong.work@gmail.com
          </a>
          <a
            className="text-white mb-3 text-decoration-none d-flex align-items-center gap-2"
            href="tel:0352498496"
          >
            <FaPhone />
            Phone Number: 0352498496
          </a>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="my-2 border-bottom"
      >
        <h2 className="text-white d-flex align-items-center gap-2">
          <IoIosInformationCircleOutline />
          Get to Know Me
        </h2>
        <p className="text-white p-2">
          As a final-year computer science student, I am passionate about
          technology and possess a strong foundation in programming, algorithms,
          and software development. I specialize in artificial intelligence and
          machine learning, with hands-on experience in diverse projects. I am
          an avid learner, always seeking new challenges to stay at the
          forefront of advancements.
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="my-2"
      >
        <h2 className="text-white d-flex align-items-center gap-2">
          <IoSchoolOutline />
          Education
        </h2>
        <div className="d-flex flex-wrap gap-2">
          <img
            src="/uit.png"
            alt="cover"
            className="img-fluid mb-2"
            style={{
              width: window.innerWidth >= 768 ? "50%" : "100%",
            }}
          />
          <div className="text-white d-flex flex-column align-content-center">
            <h3 className="text-danger">
              UNIVERSITY OF INFORMATION TECHNOLOGY
            </h3>
            <h6>Major: Computer Science</h6>
            <div className="timeline">
              <p className="d-flex align-items-center gap-2 mb-0">
                <FaRegCalendarAlt />
                Start: 10/2020
              </p>
              <motion.div
                initial={{ y: -5, opacity: 1 }}
                animate={{ y: 5, opacity: 1 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 0.5,
                }}
                className="d-flex flex-column align-content-center my-1"
              >
                <IoIosArrowDown />
                <IoIosArrowDown />
                <IoIosArrowDown />
                <IoIosArrowDown />
              </motion.div>
              <p className="d-flex align-items-center gap-2 mt-2">
                <FaRegCalendarAlt />
                End: 03/2024
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
