import React from "react";
import ProjectItem from "../components/ProjectItem";
import { projectData } from "../utils/data";

const Project = () => {
  return (
    <div className="flex-column d-flex align-content-center align-items-center justify-content-center">
      {projectData.map((item, index) => (
        <ProjectItem data={item} key={index} />
      ))}
    </div>
  );
};

export default Project;
