import React from "react";

const ProjectCard = ({ data }) => {
  return (
    <div className="cardProject card overflow-hidden m-3 flex-grow-1 d-flex">
      <img
        src={data.src}
        className="card-img-top project-img overflow-hidden"
        alt="project-img"
      />
      <div className="card-body">
        <a className="card-title text-success" href={data.url}>
          {data.title}
        </a>
        <p className="card-text">{data.description}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
