// Import React, useRef, motion, and useScroll from framer-motion
import React, { useRef } from "react";
import { motion, useScroll } from "framer-motion";
import { FaGithub } from "react-icons/fa";

// Define the Item component
const ProjectItem = ({ data }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="my-5"
    >
      <div className="d-flex" ref={ref}>
        <figure className="m-5 p-2 progress1">
          <svg id="progress1" viewBox="0 0 100 100">
            <circle
              width="75"
              height="75"
              cx="50"
              cy="50"
              r="30"
              pathLength="1"
              className="bg"
            />
            <motion.circle
              cx="50"
              cy="50"
              r="30"
              pathLength="1"
              className="indicator"
              style={{ pathLength: scrollYProgress }}
            />
          </svg>
        </figure>
        <div className="border-0 position-absolute p-2 h-100 flex-wrap d-flex align-content-between">
          <div>
            <div className="overflow-hidden">
              <img
                src={data.src}
                alt="project"
                className="img-fluid border border-light rounded project-img "
              />
            </div>
            <div className="d-flex flex-column justify-content-around">
              <h3 className="project-name text-info text-center">
                {data.title}
              </h3>
              <p className="text-white">{data.description}</p>
              <p></p>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-end align-items-center gap-3">
            <a href={data.git} className="text-white fs-1 text-center">
              <FaGithub />
            </a>
            <a href={data.url} className="btn text-white border">
              View Project
            </a>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ProjectItem;
