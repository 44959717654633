import React from "react";
import { FaCircleUser } from "react-icons/fa6";
import {
  FaHome,
  FaCode,
  FaTelegram,
  FaTasks,
  FaFacebook,
  FaPhoneSquare,
} from "react-icons/fa";
import { IoLogoGithub } from "react-icons/io";
import { MdContactMail, MdMenu } from "react-icons/md";
import { Layout, Menu } from "antd";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";

const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Home", "/", <FaHome color="white" />),
  getItem("About", "/about", <FaCircleUser color="white" />),
  getItem("Project", "/projects", <FaCode color="white" />),
  getItem("Skill", "/skill", <FaTasks color="white" />),
  getItem("Contact", "/contact", <MdContactMail color="white" />),
];

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="navbar d-md-none p-1">
        <div className="container-fluid d-flex justify-content-between align-items-center p-0 mb-0">
          <div className="dropdown">
            <button
              className="btn text-white"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <MdMenu />
            </button>
            <ul className="dropdown-menu bg-black">
              <li>
                <Link className="dropdown-item" to="/">
                  <FaHome color="white" /> Home
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/about">
                  <FaCircleUser color="white" /> About
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/projects">
                  <FaCode color="white" /> Project
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/skill">
                  <FaTasks color="white" /> Skill
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/contact">
                  <MdContactMail color="white" /> Contact
                </Link>
              </li>
              <li>
                <div className="social-link d-flex justify-content-evenly">
                  <a href="https://t.me/briefhurt" className="text-white">
                    <FaTelegram />
                  </a>
                  <a href="https://github.com/frey-eth" className="text-white">
                    <IoLogoGithub />
                  </a>
                  <a
                    href="https://www.facebook.com/literally.dng"
                    className="text-white"
                  >
                    <FaFacebook />
                  </a>
                  <a href="tel:0352498496" className="text-white">
                    <FaPhoneSquare />
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="info-wrapper d-flex align-items-center justify-content-center">
            <div className="badge fs-6 text-success d-flex align-items-center">
              <div className="blinking-dot mx-2" /> <span> Web3 Engineer</span>
            </div>
            <img
              src="cvphoto2.jpg"
              className="m-1 rounded-3"
              style={{ width: "50px", height: "50px" }}
              alt="Avatar"
            />
          </div>
        </div>
      </div>
      <Layout
        style={{
          minHeight: "100vh",
          backgroundColor: "black",
          padding: "20px",
        }}
      >
        <Sider className="bg-black sider-wrapper border-end">
          <div className="info-wrapper align-content-center">
            <img src="cvphoto2.jpg" className="avatar rounded-3" alt="Avatar" />
            <div className="badge mx-3 fs-6 text-success d-flex align-items-center justify-content-evenly">
              <div className="blinking-dot" />
              Web3 Engineer
            </div>
          </div>
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
            className="p-2 bg-black text-white"
            onClick={({ key }) => navigate(key)}
          />
          <div className="social-link d-flex justify-content-evenly">
            <a href="https://t.me/briefhurt" className="text-white">
              <FaTelegram />
            </a>
            <a href="https://github.com/frey-eth" className="text-white">
              <IoLogoGithub />
            </a>
            <a
              href="https://www.facebook.com/literally.dng"
              className="text-white"
            >
              <FaFacebook />
            </a>
            <a href="tel:0352498496" className="text-white">
              <FaPhoneSquare />
            </a>
          </div>
        </Sider>

        <Layout>
          <Content
            style={{
              backgroundColor: "black",
            }}
          >
            <div className="scrollable-content" id="scrollable-content">
              <Outlet />
              <Footer
                style={{
                  textAlign: "center",
                  backgroundColor: "black",
                }}
              >
                {location.pathname !== "/contact" && (
                  <Link className="text-decoration-none" to="/contact">
                    <h1 className="connect-footer my-5 text-white text-center">
                      Let's connect
                    </h1>
                  </Link>
                )}

                <p className="text-white">
                  © Copyright 2023. All rights Reserved.
                </p>
              </Footer>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
