import React from "react";
import { motion } from "framer-motion";
import { BsBootstrapFill } from "react-icons/bs";
import { IoLogoHtml5, IoLogoJavascript } from "react-icons/io5";
import { IoLogoCss3 } from "react-icons/io";
import { FaReact, FaCode } from "react-icons/fa6";
import { SiFlutter } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { FaNode } from "react-icons/fa";

const Skill = () => {
  const calculatePosition = (radius, totalIcons, index) => {
    const angle = (2 * Math.PI * index) / totalIcons;
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    const transformOriginX = (x / radius + 1) * 50 + "%"; // Calculate percentage based on x position
    const transformOriginY = (y / radius + 1) * 50 + "%";
    return { x, y, transformOriginX, transformOriginY };
  };

  const IconsAroundCircle = () => {
    const radius = 140;
    const totalIcons = 8;
    const scanningLineRadius = radius + 20;

    return (
      <motion.div
        className="skills-wrapper d-flex justify-content-center align-items-center"
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 5, ease: "linear" }}
      >
        {[
          BsBootstrapFill,
          SiMongodb,
          IoLogoHtml5,
          IoLogoCss3,
          FaReact,
          SiFlutter,
          IoLogoJavascript,
          FaNode,
        ].map((Icon, index) => {
          const { x, y, transformOriginX, transformOriginY } =
            calculatePosition(radius, totalIcons, index);
          const iconColors = [
            "CornflowerBlue",
            "green",
            "orange",
            "DeepSkyBlue",
            "aqua",
            "aqua",
            "yellow",
            "green",
          ];
          return (
            <motion.div
              key={`icon-${index}`}
              className="icon-container"
              style={{
                x,
                y,
                position: "absolute",
                transformOrigin: `${transformOriginX} ${transformOriginY}`,
              }}
            >
              <Icon
                className="random-icon"
                style={{ color: iconColors[index] }}
              />
            </motion.div>
          );
        })}
        <motion.div
          className="scanning-line"
          initial={{ rotate: -360 }}
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 4, ease: "linear" }}
          style={{
            width: `${scanningLineRadius * 1.3}px`,
            height: "2px",
            borderRadius: "1px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transformOrigin: "0% 50%",
          }}
        >
          <motion.div
            className="light-beam"
            initial={{ opacity: 0.7 }}
            animate={{ opacity: 1 }}
            transition={{ repeat: Infinity, duration: 0.2, ease: "linear" }}
            style={{
              background: `linear-gradient(to right, rgba(4, 202, 86, 0.6), rgba(4, 202, 86, 0))`,
              width: `${scanningLineRadius * 1.3}px`,
              height: `${scanningLineRadius * 1.3}px`,
              position: "absolute",
              top: "50%",
              left: "0%",
              transformOrigin: "0% 50%",
            }}
          />
        </motion.div>
      </motion.div>
    );
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center py-4 overflow-hidden">
      <div className="center-image d-flex align-items-center justify-content-center rounded-wrapper flex-wrap overflow-hidden">
        <IconsAroundCircle />
        <FaCode className="text-white position-absolute z-1 fs-1" />
        {/* <img
          src="/cv.jpg"
          alt="Pham Van Duong"
          height="70px"
          width="70px"
          className="z-1 position-absolute"
        /> */}
      </div>
      <h2 className="mt-5 text-white">Description</h2>
      <div className="d-flex flex-row flex-wrap text-white justify-content-center w-100 border-top p-3">
        <div className="d-flex flex-column flex-grow-1 text-center border p-2">
          <h4 className="">Front-End</h4>
          <p>Javascript, TypeScript, Dart</p>
          <p>ReactJS, ReactNative, Flutter, NextJs, HTML5, CSS3</p>
          <p>M-UI, Ant-Design, Frammer, Figma, Boostrap</p>
          <p>UI Design, Redux, Redux Toolkit, etc.</p>
        </div>
        <div className="d-flex flex-column flex-grow-1 text-center border p-2">
          <h4 className="text-center">Back-End</h4>
          <p>Javascript, Python</p>
          <p>My SQL, ExpressJS, NodeJS, Django</p>
          <p>MongoDB, Cloudinary, Firebase, Restful API</p>
          <p>Database design, Develope API, etc.</p>
        </div>
      </div>
      <h3 className="text-white mt-3">Problem Soving</h3>
      <div className="w-100 border-top d-flex justify-content-center p-3">
        <a href="https://coursera.org/verify/5E3YERZKNG8B">
          {" "}
          <img src="/certificate.png" alt="" className="img-fluid" />
        </a>
      </div>
    </div>
  );
};

export default Skill;
