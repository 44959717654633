export const projectData = [
  {
    title: "DevTruyen.com",
    url: "https://www.devtruyen.com/",
    git: "https://github.com/frey-eth/",
    src: "devtruyen1.png",
    description:
      "Website to read manga using NextJS, TailWindCSS, NodeJs, Python... ",
  },

  {
    title: "Openverse",
    url: "https://openverse-market.vercel.app/",
    src: "/openverse.png",
    git: "https://github.com/frey-eth",
    description:
      "NFT Marketplace Using NextJS, TailWindCSS, Solidity, Hardhat, Ethers.js, IPFS,...",
  },

  {
    title: "Ecommerce Website",
    url: "https://amazune-seven.vercel.app/",
    src: "project-img.png",
    git: "https://github.com/frey-eth/ECommerce-FrontEnd/",
    description:
      "Modern E-Commerce project using JavaScript, HTML, CSS, Bootstrap, MERN Stack ( MongoDB, ExpressJS, Reactjs, Nodejs ), Cloudinary.",
  },

  {
    title: "Playere",
    url: "https://github.com/frey-eth/playere",
    git: "https://github.com/frey-eth/playere",
    src: "playere.png",
    description:
      "App for find Gamer Friend Using Flutter, Firebase, Google API,...",
  },

  {
    title: "Twitter",
    url: "https://github.com/frey-eth/twitter-flutter",
    git: "https://github.com/frey-eth/twitter-flutter",
    src: "twitter.jpg",
    description:
      "Twitter Clone Using Flutter, Riverpod, Provider, Appwrite,... ",
  },
];

const img_size = [1695, 824];
