import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  message: Yup.string().required("Message is required"),
});
const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await axios.post("https://formspree.io/f/maygrzva", values);
      toast.success("You have send an message to Duong!!");
      formik.resetForm();
    },
  });
  return (
    <div className="contact-wrapper p-5">
      <ToastContainer />
      <h1 className="text-white">Let's Connect!</h1>
      <form
        onSubmit={formik.handleSubmit}
        className="d-flex flex-row py-5 gap-3 flex-wrap"
      >
        <div className="d-flex flex-column flex-wrap flex-grow-1 contact-infomation">
          <div className="d-flex flex-column text-white">
            <h3>Contact</h3>
            <a
              className="mb-3 text-secondary text-decoration-none d-flex align-items-center gap-2"
              href="mailto:phamvanduong.work@gmail.com"
            >
              <MdEmail />
              phamvanduong.work@gmail.com
            </a>
            <a
              className="text-secondary mb-3 text-decoration-none d-flex align-items-center gap-2"
              href="tel:0352498496"
            >
              <FaPhone />
              0352498496
            </a>
          </div>
          <div className="d-flex flex-column text-white">
            <h3>Social Media</h3>
            <a
              className="text-secondary mb-3 text-decoration-none d-flex align-items-center gap-2"
              href="https://www.facebook.com/literally.dng"
            >
              <FaFacebook />
              Facebook
            </a>
            <a
              className="text-secondary mb-3 text-decoration-none d-flex align-items-center gap-2"
              href="https://www.instagram.com/literally_dng"
            >
              <FaInstagram />
              Instagram
            </a>
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 gap-2">
          <label htmlFor="name" className="text-white">
            Full Name
          </label>
          <input
            id="name"
            name="name"
            type="name"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="text-danger">{formik.errors.name}</p>
          ) : null}
          <label htmlFor="email" className="text-white">
            Email Address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="text-danger">{formik.errors.email}</p>
          ) : null}
          <label htmlFor="message" className="text-white">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            type="message"
            rows="5"
            className="form-control"
            style={{ height: "200px" }}
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? (
            <p className="text-danger">{formik.errors.message}</p>
          ) : null}
          <button
            type="submit"
            className="d-flex align-self-end btn bg-white m-3 text-success"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
