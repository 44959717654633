import React from "react";
import { MdPlace } from "react-icons/md";
import ProjectCard from "../components/ProjectCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { projectData } from "../utils/data";
const Home = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: window.innerWidth >= 768 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="home-container p-2 overflow-x-hidden">
      <div className="row">
        <h3 className="animate-charcter text-white">Hello! I'm Duong.</h3>
      </div>
      <div className="title p-1">
        <div className="monitor-wrapper">
          <div className="monitor">
            <p>
              I am a Full-stack developer, I can work both on Web and Mobile
              development with all Tech stack.
            </p>
          </div>
        </div>
      </div>
      <div className="location d-flex py-4 align-content-center justify-content-between">
        <p className="d-flex align-items-center text-white text-uppercase align-content-center">
          <MdPlace className="icon" />
          Ho Chi Minh City
        </p>
        <Link
          className="d-flex flex-row-reverse align-items-end text-decoration-none p-2"
          to="/about"
        >
          <div className="btn text-white d-flex align-items-center border rounded-3">
            About me
            <IoIosArrowForward />
          </div>
        </Link>
      </div>
      <div className="flex-column overflow-hidden">
        <h3 className="text-white">Recent Project</h3>
        <div className="">
          <Slider {...settings}>
            {projectData.map((item, index) => (
              <ProjectCard data={item} key={index} />
            ))}
          </Slider>
        </div>
        <Link
          className="d-flex flex-row-reverse align-items-end text-decoration-none p-2"
          to="/projects"
        >
          <div className="btn text-white d-flex align-items-center border rounded-3">
            View All Projects
            <IoIosArrowForward />
          </div>
        </Link>
      </div>

      <div className="flex-column overflow-hidden">
        <h3 className="text-white">Key Skills</h3>
        <div className="skill-wrapper">
          <table>
            <tr>
              <td align="top" width="50%">
                <th className="text-white">Frontend</th>
                <div align="center">
                  <a
                    href=" "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/react-original-wordmark.svg"
                      alt="React"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://getbootstrap.com/docs/3.4/javascript/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/bootstrap-plain.svg"
                      alt="Bootstrap"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.w3schools.com/css/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/css3-original-wordmark.svg"
                      alt="CSS3"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://en.wikipedia.org/wiki/HTML5"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/html5-original-wordmark.svg"
                      alt="HTML5"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.javascript.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/javascript-original.svg"
                      alt="JavaScript"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.typescriptlang.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/typescript-original.svg"
                      alt="TypeScript"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://redux.js.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/redux-original.svg"
                      alt="Redux"
                      height="50"
                    />
                  </a>
                </div>
              </td>
              <td valign="top" width="50%">
                <th className="text-white">Backend</th>
                <div align="center">
                  <a
                    href="https://www.javascript.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/javascript-original.svg"
                      alt="JavaScript"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://nodejs.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/nodejs-original-wordmark.svg"
                      alt="Node.js"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.python.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/python-original.svg"
                      alt="Python"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://expressjs.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/express-original-wordmark.svg"
                      alt="Express.js"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.mongodb.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/mongodb-original-wordmark.svg"
                      alt="MongoDB"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.mysql.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/mysql-original-wordmark.svg"
                      alt="MySQL"
                      height="50"
                    />
                  </a>
                  <a
                    href="https://www.djangoproject.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://profilinator.rishav.dev/skills-assets/django-original.svg"
                      alt="Django"
                      height="50"
                    />
                  </a>
                </div>
              </td>
              <td valign="top" width="33%"></td>
            </tr>
          </table>
        </div>

        <Link
          className="d-flex flex-row-reverse align-items-end text-decoration-none p-2"
          to="/skill"
        >
          <div className="btn text-white d-flex align-items-center border rounded-3">
            View All Skills
            <IoIosArrowForward />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Home;
